import Vue from 'vue';
import Vuex from 'vuex';
import { getUserMenu } from '@/api/api';
import Home from '@/views/home/home';
import tools from '@/util/tools';

import router from '@/router';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    test: true,
    params: {
      roleId: ''
    },
    routeList: ''
  },
  mutations: {},
  actions: {
    /**
     *〈 获取用户组菜单 〉
     */
    getUserMenuAction() {
      let self = this;
      let params = {
        roleId: ''
      };
      if (sessionStorage.getItem('APP_ROLES_ID')) {
        params.roleId = sessionStorage.getItem('APP_ROLES_ID');
      } else if (self.state.roles) {
        params.roleId = self.state.roles;
      } else {
        params.roleId = tools.getUsetInfo().roles[0].id;
      }
      return getUserMenu(params)
        .then((res) => {
          // console.log('->生成router');
          if (res.code == '0000') {
            var bar_list = [];
            // 写入父路由
            var parentRouter = [
              {
                path: '/home',
                name: 'Home',
                component: Home
                // redirect: bar_list[0].path
              },
              {
                path: '/error',
                name: 'Error',
                component: () => import('@/views/error/error')
              }
            ];
            parentRouter.forEach((parent) => {
              router.addRoute('/', {
                path: parent.path,
                name: parent.name,
                redirect: parent.redirect,
                component: parent.component
              });
            });
            // 写入子路由
            res.data.forEach((item) => {
              if (item.checked) {
                bar_list.push({ id: item.id, name: item.name, path: item.url });
                router.addRoute('Home', {
                  path: item.url,
                  name: item.enName,
                  // component: () => import(`@/views${item.url}`)
                  // component: () => (resolve) => require([`@/views${item.url}`], resolve)
                  component: resolve => require([`@/views${item.url}`], resolve)
                  // component:    () => { return (resolve) => require([`@/views/${item.url}.vue`], resolve) }
                });
              }
            });
            sessionStorage.setItem('APP_USER_MUNE', JSON.stringify(bar_list)); //写入本地校验（不严谨）
            console.log('->getRoutes', router.getRoutes());
            self.state.routeList = router.getRoutes();
            // console.log('->state', self.state.routeList);
            router.push({ path: self.state.routeList[2].path }); //统一重定向
            return res;
          } else if (res.code == '1007') {
            window.location.replace(process.env.VUE_APP_CAS_URL + `http://${window.location.host}/`);
          }
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    }
  },
  modules: {}
});
