import http from '@/util/http';
import http2 from '@/util/http2';

/**
 *〈 cas 〉
 */
export function cas(params) {
  return http({
    url: '/api/cas',
    method: 'get',
    params: params
  });
}

/**
 *〈 user 〉
 */
export function getUser() {
  return http({
    Authorization: true,
    url: '/api/user/info',
    method: 'get'
  });
}

/**
 *〈 menu 〉
 */
export function getDeptTree() {
  return http({
    Authorization: true,
    url: '/api/user/dept/tree',
    method: 'get',
  });
}

/**
 *〈 menu 〉
 */
export function getUserMenu(params) {
  return http({
    Authorization: true,
    url: '/api/user/menu',
    method: 'get',
    params: params
  });
}

/**
 *〈 save menu 〉
 */
export function saveUserMenu(params) {
  return http({
    Authorization: true,
    url: '/api/user/roleMenu',
    method: 'post',
    data: params
  });
}

/**
 *〈 save user 〉
 */
export function saveUserRole(params) {
  return http({
    Authorization: true,
    url: '/api/user/userRole',
    method: 'post',
    data: params
  });
}

/**
 *〈 add user 〉
 */
 export function insertUserRole(params) {
  return http({
    Authorization: true,
    url: '/api/user/userRole/insert',
    method: 'post',
    data: params
  });
}

/**
 *〈 del user 〉
 */
 export function deleteUserRole(params) {
  return http({
    Authorization: true,
    url: '/api/user/userRole/delete',
    method: 'post',
    data: params
  });
}

/**
 *〈 创建用户组 〉
 */
export function setRole(params) {
  return http({
    Authorization: true,
    url: '/api/sys/role',
    method: 'post',
    data: params
  });
}

/**
 *〈 获取自定义用户组 〉
 */
export function getRoleList() {
  return http({
    Authorization: true,
    url: '/api/user/role',
    method: 'get'
  });
}

/**
 *〈 获取角色已有的用户列表 〉
 */
export function getUserRole(params) {
  return http({
    Authorization: true,
    url: '/api/user/userRole',
    method: 'get',
    params: params
  });
}

/**
 *〈 申请记录 -> 获取项目列表 〉
 */
export function getProjectList(params) {
  return http2({
    Authorization: true,
    url: '/api/project/list',
    method: 'post',
    data: params
  });
}

/**
 *〈 申请记录 -> 新增/编辑 〉
 */
export function setProject(params) {
  return http({
    Authorization: true,
    url: '/api/project',
    method: 'post',
    data: params
  });
}

/**
 *〈 申请记录 -> 删除 〉
 */
export function delProject(params) {
  return http({
    Authorization: true,
    url: '/api/project/delete',
    method: 'delete',
    data: params
  });
}

/**
 *〈 申请记录 -> 终止 〉
 */
export function endProject(params) {
  return http({
    Authorization: true,
    url: `api/project/end/${params.id}`,
    method: 'get'
  });
}

/**
 *〈 申请记录 -> 下载审批记录及施工图 〉
 */
export function downloadPdf(params) {
  return http({
    Authorization: true,
    url: `api/project/downloadPdf/${params.id}`,
    method: 'post',
    data: {id:params.id}
  });
}

/**
 *〈 申请记录 -> 详情 〉
 */
export function getProjectDetail(params) {
  return http({
    Authorization: true,
    url: `api/project/${params.id}`,
    method: 'get'
  });
}

/**
 *〈 申请记录 -> 施工文件上传 〉
 */
export function setProjectUploadWorkDraw(params) {
  return http({
    Authorization: true,
    url: '/api/project/uploadWorkDraw',
    method: 'post',
    data: params
  });
}

/**
 *〈 内容陈述 -> 获取列表 〉
 */
export function getContentPresentList(params) {
  return http({
    Authorization: true,
    url: '/api/contentPresent/list',
    method: 'post',
    data: params
  });
}

/**
 *〈 内容陈述 -> 新增/编辑 〉
 */
export function setContentPresent(params) {
  return http({
    Authorization: true,
    url: '/api/contentPresent',
    method: 'post',
    data: params
  });
}

/**
 *〈 内容陈述 -> 删除 〉
 */
export function delContentPresent(params) {
  return http({
    Authorization: true,
    url: '/api/contentPresent/delete',
    method: 'delete',
    data: params
  });
}

/**
 *〈 会审批准 -> 提交 〉
 */
export function setProjectAudit(params) {
  return http({
    Authorization: true,
    url: '/api/project/audit',
    method: 'post',
    data: params
  });
}

/**
 *〈 会审人员 -> 列表 〉
 */
export function getAssessorList(params) {
  return http({
    Authorization: true,
    url: '/api/assessor/list',
    method: 'post',
    data: params
  });
}

/**
 *〈 部门人员列表 〉
 */
export function getDeptUserList(params) {
  return http({
    Authorization: true,
    url: '/api/user/list',
    method: 'post',
    data: params
  });
}

/**
 *〈 会审人员 -> 新增/编辑 〉
 */
export function setAssessor(params) {
  return http({
    Authorization: true,
    url: '/api/assessor',
    method: 'post',
    data: params
  });
}

/**
 *〈 会审人员 -> 删除 〉
 */
export function delAssessorList(params) {
  return http({
    Authorization: true,
    url: '/api/assessor/delete',
    method: 'delete',
    data: params
  });
}

/**
 *〈 删除 〉
 */
 export function delSysRole(params) {
  return http({
    Authorization: true,
    url: `api/sys/role/${params.id}`,
    method: 'delete',
    data: params
  });
}

/**
 *〈 申请撤回 〉
 */
 export function getWithdraw(params) {
  return http({
    Authorization: true,
    url: `/api/project/recall/${params.id}`,
    method: 'get'
  });
}

/**
 *〈 下载文件 〉
 */
 export function getDownloadByUrlAndName(params) {
  var url = process.env.NODE_ENV === 'development' ? 'workdraw-front.apps.k8sits.sustech.edu.cn' : window.location.host;
  window.open(`https://${url}/api/project/downloadByUrlAndName?url=${params.url}&fileName=${params.fileName}`);
  // return http({
  //   Authorization: true,
  //   url: `/api/project/downloadByUrlAndName`,
  //   method: 'get',
  //   params: params
  // });
}


/**
 *〈 申请记录 -> 获取项目列表 〉
 */
 export function getDepartmentProjectList(params) {
  return http({
    Authorization: true,
    url: '/api/project/deptProject',
    method: 'post',
    data: params
  });
}

/**
 *〈 导出申请 new 〉
 */
 export function getExportProject(params) {
  var url = process.env.NODE_ENV === 'development' ? 'workdraw-front.apps.k8sits.sustech.edu.cn' : window.location.host;
  window.open(`https://${url}/api/project/downloadZip?id=${params.id}`);
  // https://workdraw-front.apps.k8sits.sustech.edu.cn/api/project/downloadZip?id=290
}

/**
 *〈 test 〉
 */
 export function getTest(params) {
  return http({
    Authorization: true,
    url: '/api/apis/api/question/findAll',
    method: 'get',
    data: params
  });
}


// 新增/修改项目信息
export function addOrUpdateProjectInformation(params) {
  return http({
    Authorization: true,
    url: '/api/project',
    method: 'post',
    data: params
  });
}

// 获取基建专员列表
export function getInfrastructureList(params) {
  return http({
    Authorization: true,
    url: '/api/infrastructure/list',
    method: 'post',
    data: params
  });
}

// 显示新增基建专员列表
// /infrastructure/user
export function getInfrastructureUserList(params) {
  return http({
    Authorization: true,
    url: '/api/infrastructure/user',
    method: 'post',
    data: params
  });
}

// 新增（编辑）基建专员配置信息
export function addNewInfrastructure(params) {
  return http({
    Authorization: true,
    url: '/api/infrastructure',
    method: 'post',
    data: params
  });
}


// 删除基建专员配置信息
// /infrastructure/delete
export function deleteInfrastructure(params) {
  return http({
    Authorization: true,
    url: '/api/infrastructure/delete',
    method: 'delete',
    data: params
  });
}

// 导入基建专员信息
// /infrastructure/import
export function infrastructureImport(params) {
  return http({
    Authorization: true,
    url: '/api/infrastructure/import',
    method: 'post',
    data: params
  });
}

// 基建专员列表代办交接
// /infrastructure/transfer
export function infrastructureTransfer(params) {
  return http({
    Authorization: true,
    url: '/api/infrastructure/transfer',
    method: 'post',
    data: params
  });
}

// 会审人员
// 显示审批人员交接
// /assessor/showTransfer
export function getAssessorTransferList(params) {
  return http({
    Authorization: true,
    url: '/api/assessor/showTransfer',
    method: 'post',
    data: params
  });
}
// 审批人员交接
export function assessorTransfer(params) {
  return http({
    Authorization: true,
    url: '/api/assessor/transfer',
    method: 'post',
    data: params
  });
}

// 显示审批人员交接
// /infrastructure/showTransfer
export function getInfrastructureTransferList(params) {
  return http({
    Authorization: true,
    url: '/api/infrastructure/showTransfer',
    method: 'post',
    data: params
  });
}

// 获取部门列表
// /project/depts
export function getDepartmentList() {
  return http({
    Authorization: true,
    url: '/api/project/depts',
    method: 'get',
  });
}




// 撤回审批
// /project/withdraw
export function withdraw(params) {
  return http({
    Authorization: true,
    url: `/api/project/withdraw?projectSerial=${params.projectSerial}`,
    method: 'post'
  });
}


// 导入基建专员信息
// /infrastructure/import
export function importFiles(params) {
  return http({
    Authorization: true,
    url: `/api/infrastructure/import`,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

// 查询填写资料标签是否显示
export function isInfrastructure(params) {
  return http2({
    Authorization: true,
    url: `/api/project/isInfrastructure?projectId=${params}`,
    method: 'get',
  });
}


// 获取项目标识
// /project/identification
export function getIdentificationList() {
  return http2({
    Authorization: true,
    url: `/api/project/identification`,
    method: 'get',
  });
}

// 退回意见内容
export function returnInfrastructure(params) {
  return http({
    Authorization: true,
    url: `/api/infrastructure/return?content=${params.content}&projectId=${params.projectId}`,
    method: 'get',
  });
}