import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import { Message, MessageBox, Notification, Loading } from 'element-ui';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.min.css'


import '@/util/auth';

import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/common.scss';
import '@/assets/style/global.scss';

Vue.use(Loading);
Vue.use(Vuex);    

Vue.config.productionTip = false;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

let localUser = localStorage.getItem('user');
if (localUser) {
  // console.log('->rquest');
  store.dispatch('getUserMenuAction').then(() => {
    // console.log('->刷新res', store.state.routeList[2].path);
    // router.push({path: store.state.routeList[2].path })
    // router.push({path: '/' })
  });
}
Vue.component();
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
