import axios from 'axios';
import tools from '@/util/tools';
// import store from '../store';
import { Notification, MessageBox } from 'element-ui';

const http = axios.create({});

http.defaults.retry = 4;
http.defaults.retryDelay = 2000;

http.interceptors.request.use(
  (config) => {
    let rolesId = localStorage.getItem('rolesId');
    var localInfo = tools.getUsetInfo();
    if (config.Authorization && localInfo.token && localInfo.roles[0].id) {
      config.headers.token = localInfo.token;
      config.headers.roleId = rolesId ? rolesId : localInfo.roles[0].id;
    } else if (config.Authorization && localInfo.token && localInfo.roles[0].id && config.url !== '/cas') {
      Notification({ type: 'error', title: `账号信息异常`, message: `账号信息异常，请联系管理员` });
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

/* result */
http.interceptors.response.use(
  (result) => {
    if (result.data.code == '1001' || result.data.code == '1007') {
      // let loadingInstance = Loading.service();
      // Notification({ type: 'error', title: `Error ${result.data.code}`, duration: 10000, message: `${result.config.url}  ${result.data.msg}` });

      MessageBox.confirm(`${result.config.url}  ${result.data.msg}`, `Error ${result.data.code}`, {
        confirmButtonText: '确定',
        type: 'error',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        showCancelButton: false
      }).then(() => {
        tools.logout();
      });

      // loadingInstance.close();
      return;
    }
    return result.data;
  },
  function axiosRetryInterceptor(err) {
    var config = err.config;
    if (err && err.response.status) {
      return Promise.reject(err);
    }
    // 如果配置不存在或未设置重试选项，reject(err)
    if (!config || !config.retry) {
      return Promise.reject(err);
    }

    // 设置变量记录重试请求次数
    config.__retryCount = config.__retryCount || 0;

    // 检测重试请求次数
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(err);
    }

    // Increase the retry count
    config.__retryCount += 1;

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function(resolve) {
      setTimeout(function() {
        resolve();
      }, config.retryDelay || 1);
    });

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function() {
      return axios(config);
    });
  }
);

export default http;
