<template>
  <v-app v-scroll="onScroll">
    <AppBar :scroll_data.sync="scroll_data" />
    <v-main>
      <router-view :scroll_data.sync="scroll_data"></router-view>
    </v-main>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-alert prominent :icon="false" class="del-mb pa-4 py-2" border="left" :color="alert_msg_data.color" dismissible :type="alert_msg_data.type">
            <div>
              <p class="subtitle-1 del-mb">{{alert_msg_data.title}}</p>
              <span class="caption">{{alert_msg_data.msg}}</span>
            </div>
            <template v-slot:close>
              <v-icon @click="dialog = false">mdi-close-circle-outline</v-icon>
            </template>
          </v-alert>
        </v-dialog>
      </v-row>
    </template>
    <p class="default-background white--text d-flex justify-center py-2 mb-0 mt-10"><span class="text-caption">版权信息 &copy; 南方科技大学</span></p>
  </v-app>
</template>

<script>
import AppBar from '@/components/app-bar.vue';

export default {
  provide() {
    return {
      alertMsg: this.alertMsg
    };
  },
  data() {
    return {
      snackbar: true,
      scroll_data: {
        height: 192,
        extended: true,
        sign: true
      },
      alert: false,
      dialog: false,
      alert_msg_data: {
        type: 'info',
        title: '',
        msg: '',
        color: 'blue',
        close: false
      }
    };
  },
  /* import引入的组件 */
  components: { AppBar },
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {},
  /* 方法集合 */
  methods: {
    onScroll(e) {
      let self = this;
      if (e.target.scrollingElement.scrollTop >= 100) {
        self.scroll_data.height = 64;
        self.scroll_data.extended = false;
      } else if (e.target.scrollingElement.scrollTop < 100) {
        self.scroll_data.height = 192;
        self.scroll_data.extended = true;
      }
    },
    alertMsg({ title, msg, type }) {
      let self = this;
      self.alert_msg_data.title = title || '温馨提示';
      self.alert_msg_data.msg = msg;
      self.alert_msg_data.type = type || 'info';
      switch (type) {
        case 'error':
          self.alert_msg_data.color = 'red';
          self.alert_msg_data.icon = 'mdi-alert-octagon-outline';
          break;
        case 'info':
          self.alert_msg_data.color = 'blue';
          break;
        case 'success':
          self.alert_msg_data.color = 'green';
          break;
        default:
          break;
      }

      self.dialog = true;
    }
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {},
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {},
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {}
};
</script>
<style lang="scss" scoped>
</style>
