<template>
  <v-app-bar transition="slide-x-transition" app color="#2D9186" dark class="app-bar-expand" :extended="scroll_data.extended" flat :height="scroll_data.height">
    <v-container :class="[scroll_data.extended ? 'align-start' : '', 'py-0 fill-height d-flex justify-space-between']">
      <div class="w-100 d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <v-img class="shrink mr-2 hidden-sm-only hidden-md-only" contain src="https://ehall.sustech.edu.cn/portal/custom/img/school/logo/logo.png" transition="scale-transition" width="200" />
          <span class="text-md-h5 text-sm-subtitle-1 ml-md-10">施工图会审系统</span>
        </div>
        <div>
          <v-tabs height="35" v-model="toggle_bar" background-color="#2D7E74" right class="hidden-sm-only">
            <v-tabs-slider color="#FF9933"></v-tabs-slider>
            <v-tab @click="_viewToggleAction(nav)" replace :to="nav.path" v-for="nav in bar_list" :key="nav.name">
              {{ nav.name }}
            </v-tab>

            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on">
                  {{user_info.name}}({{user_role_name}})
                </v-tab>
              </template>

              <v-list>
                <div v-for="item in rolesArray" :key="item.id" @click="_selectIdentityAction(item)">
                  <v-list-item link v-if="item.id!==roles_id">
                    <v-list-item-title class="text-body-2 text-center">{{item.roleName}}</v-list-item-title>
                  </v-list-item>
                </div>
                <v-list-item v-if="0" link @click="_toggleIdentity">
                  <v-list-item-title class="text-body-2 text-center">切换角色</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="_out">
                  <v-list-item-title class="text-body-2 text-center">退出登录</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          </v-tabs>

        </div>
      </div>
    </v-container>

    <template>
      <v-dialog v-model="dialogs" persistent max-width="500">
        <v-card>
          <v-card-title class="justify-center">
            请您选择身份
          </v-card-title>
          <v-card-text class="text-center" v-if="rolesArray.length>0">
            <v-chip @click="_selectIdentityAction(item)" class="cursor-p ma-2" v-for="item in rolesArray" :key="item.id" color="#2D9186" dark label>
              <v-icon left>
                mdi-account-circle-outline
              </v-icon>
              {{item.roleName}}
            </v-chip>
          </v-card-text>
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#2D9186" elevation="0" dark @click="dialogs = false">
              确认
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </template>

  </v-app-bar>
</template>

<script>
import Tools from '@/util/tools';
export default {
  props: ['scroll_data'],
  data() {
    return {
      user_info: Tools.getUsetInfo(),
      rolesArray: Tools.getUsetInfo().roles,
      user_role_name: Tools.getUsetInfo().roles[0].roleName,
      roles_id: '',
      dialogs: false,
      toggle_bar: 0,
      bar_list: [
        // { id: 1001, name: '申请记录', path: '/application-record/list' },
        // { id: 2001, name: '我的会审', path: '/review-list/list' },
        // { id: 3001, name: '会审人员配置', path: '/review-person-config/list' },
        // { id: 4001, name: '内容陈述配置', path: '/content-config/list' },
        // { id: 9001, name: '权限管理', path: '/auth-setting/list' }
      ]
    };
  },
  /* import引入的组件 */
  components: {},
  /* 监听属性 类似于data概念 */
  computed: {},
  /* 监控data中的数据变化 */
  watch: {},
  /* 方法集合 */
  methods: {
    _viewToggleAction() {
      // let self = this;
      // console.log('->', item.path);
      // self.$router.push({ path: item.path });
    },
    _toggleIdentity() {
      let self = this;
      self.dialogs = true;
    },
    _selectIdentityAction(item) {
      let self = this;
      localStorage.setItem('rolesId', item.id);
      self.dialogs = false;
      sessionStorage.setItem('APP_ROLES_ID', item.id);
      self.$store.state.roles = item.id;
      self.user_role_name = item.roleName;

      self.$router.$addRoutes();
    },
    _out() {
      let self = this;
      self
        .$confirm('确认退出?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        })
        .then(() => {
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.replace('https://cas-test.sustech.edu.cn/cas/logout');
        })
        .catch(() => {
          console.log('取消');
        });
    }
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    let self = this;
    self.bar_list = JSON.parse(sessionStorage.getItem('APP_USER_MUNE'));
    // console.log(self.bar_list,11111111111111111111111111111)
    self.roles_id = parseInt(sessionStorage.getItem('APP_ROLES_ID'));
    Tools.getUsetInfo().roles.forEach((roles) => {
      if (roles.id == self.roles_id) {
        self.user_role_name = roles.roleName;
      }
    });
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {},
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {}
};
</script>
<style lang="scss">
.app-bar-expand {
  transition: all 0.5s ease !important;
  overflow: hidden;
}
.app-bar-expand .v-tabs {
  flex: unset !important;
  width: auto !important;
}
</style>
<style lang="scss" scoped></style>
