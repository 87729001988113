import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
Vue.use(VueRouter);
const router = new VueRouter();
router.$addRoutes = () => {
  router.matcher = new VueRouter({ mode: 'hash' }).matcher;
  store.dispatch('getUserMenuAction').then(() => {
  });
};
export default router;
